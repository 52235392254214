import { OrderContext } from 'libs/contexts/order-context'
import { useContext } from 'react'

function useOrder() {
    const context = useContext(OrderContext)

    return context
}

export { useOrder }
