import { Loading } from 'modals'
import React, { memo } from 'react'

import { Container } from './page-container.styled'

interface PageContainerProps {
    className?: string
    draggable?: boolean
    loading?: boolean
    children?: any
}
const PageContainer: React.FC<PageContainerProps> = memo(({ children, className, loading }) => {
    return (
        <Container className={className}>
            {children}
            {loading && <Loading show />}
        </Container>
    )
})
export { PageContainer }
