import classNames from 'classnames'
import React, { memo, useCallback, useRef, useState } from 'react'

import { Container, ContainerLoading, Title } from './button.styled'

export interface CustomButtonProps {
    id?: string
    title?: string
    className?: string
    outline?: boolean
    loading?: boolean
    disabled?: boolean
    position?: 'left' | 'right'
    withoutBorder?: boolean
    widthFitContainer?: boolean
    onClick?: () => Promise<void> | void
    variant?: 'secondary' | 'white' | 'danger' | 'success' | 'alert' | 'text-only'
    uppercase?: boolean
    marginBottom?: boolean
}

const Button: React.FC<CustomButtonProps> = memo(
    ({
        id,
        className,
        onClick,
        title,
        loading = false,
        disabled = false,
        variant,
        uppercase = true,
        ...props
    }) => {
        const stopRef = useRef(false)
        const [breaking, setBreaking] = useState(false)

        const _onClick = useCallback(async () => {
            if (!stopRef.current) {
                stopRef.current = true
                if (onClick) {
                    setBreaking(true)
                    await onClick()
                    setBreaking(false)
                }
                setTimeout(() => {
                    stopRef.current = false
                }, 300)
            }
        }, [onClick])

        return (
            <Container
                id={id}
                type="button"
                variant={variant}
                disabled={disabled || loading || breaking}
                className={classNames('button', className)}
                onClick={_onClick}
                {...props}
            >
                {(loading || breaking) && <Loading variant={variant} />}

                <Title
                    id="title-button"
                    className="title-button"
                    transparent={loading || breaking}
                    variant={variant}
                    uppercase={uppercase}
                    {...props}
                >
                    {title}
                </Title>
            </Container>
        )
    },
)

const Loading: React.FC<CustomButtonProps> = memo(props => {
    return (
        <ContainerLoading {...props}>
            <div className="lds-ring">
                <div />
                <div />
                <div />
                <div />
            </div>
        </ContainerLoading>
    )
})

export { Button }
