import { axios } from 'libs/helpers/connection'
import { IBag } from 'types/bag'

async function get(id: any) {
    return axios.get<IBag>(`/${id}`)
}
async function getBag(uuid: any) {
    return axios.get<IBag>(`/bag/${uuid}`)
}

export default {
    get,
    getBag,
}
