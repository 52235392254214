import styled from 'styled-components'

const loadLogo = 'assets/images/logo-gif.gif'

const LoadingScreenContainer = styled.div`
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.elevation.loading};
    backdrop-filter: brightness(0.4);
    color: white;
`

const IconContainer = styled.div`
    width: 80px;
    height: 80px;
    position: relative;
    transform: scale(1);
    margin-bottom: 10px;
`

const LoadingIcon = styled.img.attrs({
    src: loadLogo,
})`
    object-fit: contain;
`

const LoadingText = styled.div`
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Barlow';
    text-transform: uppercase;
    letter-spacing: 3px;
    animation: pulse 5s infinite;
    margin-bottom: 10px;
    overflow: hidden;
    border: 0;
    @keyframes pulse {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }
`

export { IconContainer, LoadingIcon, LoadingScreenContainer, LoadingText }
