import styled from 'styled-components'

const Container = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
    user-select: none;
    pointer-events: none;
`

const Badge = styled.img`
    object-fit: contain;
    width: 50px;
    height: 50px;
    pointer-events: none;
    user-select: none;
`

export { Badge, Container }
