import { useResize } from 'libs/hooks'
import { memo, useCallback, useEffect, useRef, useState } from 'react'
import { IDestiny } from 'types/bag'

import { CanvasStyled, Container } from './canvas.styled'

type Props = {
    hasZoom?: boolean
    destiny: IDestiny
}
const Canvas: React.FC<Props> = memo(({ destiny, hasZoom }) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>({} as HTMLCanvasElement)
    const startRender = useRef(false)

    const zoomRef = useRef<number>(1)
    // const lastTouchDistanceRef = useRef<number | null>(null)
    const backgroundImage = useRef<HTMLImageElement>(new Image())
    const maxSizeRef = useRef<number>(0)
    const size = useResize(containerRef)

    const [imageLoaded, setImageLoaded] = useState(false)

    const _renderCanvas = useCallback(() => {
        const canvas = canvasRef.current

        if (!canvas) {
            return
        }
        const ctx = canvas.getContext('2d')

        if (!ctx) {
            return
        }

        const maxSize = maxSizeRef.current

        canvas.width = maxSize
        canvas.height = maxSize

        if (hasZoom) {
            destiny.maps.forEach(map => {
                setFocus(ctx, map.area)
            })
        }

        ctx.clearRect(0, 0, canvas.width, canvas.height)

        ctx.drawImage(backgroundImage.current, 0, 0, maxSize, maxSize)

        function scale(value: number) {
            const scaled = (maxSize * value) / 1920
            return scaled
        }

        function setFocus(context: CanvasRenderingContext2D, point: any) {
            const [fX, fY] = point.coordinates[0][0]
            const centerCanvas = maxSize / 2
            context.scale(2, 2)
            context.translate(-scale(fX), -scale(fY))
            context.translate(scale(centerCanvas) * 3.5, scale(centerCanvas) * 2.5)
        }

        function getCenterCoords(points: number[][]) {
            let x = 0
            let y = 0
            for (let i = 0; i < points.length; i++) {
                x += scale(points[i][0])
                y += scale(points[i][1])
            }
            x /= points.length
            y /= points.length
            return { x, y }
        }

        function createObject(id: number, positions: number[][], color: string, text: string) {
            if (!ctx) {
                return
            }
            if (!Array.isArray(positions) || positions.length === 0) {
                return
            }

            const zoom = zoomRef.current

            positions.pop()

            const center = getCenterCoords(positions)

            const first = positions.shift()

            if (!first) {
                return
            }

            ctx.beginPath()
            ctx.moveTo(scale(first[0]), scale(first[1]))

            positions.forEach((p, i) => {
                ctx.lineTo(scale(p[0]), scale(p[1]))
            })

            ctx.fillStyle = color

            ctx.fill()
            ctx.closePath()
            ctx.lineWidth = 1
            ctx.strokeStyle = '#000'
            ctx.stroke()

            ctx.font = `bold ${scale(32)}px Arial`
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            ctx.fillStyle = '#000'
            ctx.fillText(text, center.x, center.y)
            ctx.scale(zoom, zoom)
            return ctx
        }
        destiny.maps.forEach(item => {
            item.area.coordinates.forEach(points => {
                createObject(destiny.id, [...points], item.color, item.name)
            })
        })
    }, [destiny, hasZoom])

    useEffect(() => {
        setImageLoaded(false)
        if (!destiny?.maps?.length) {
            return
        }
        backgroundImage.current.style.objectFit = 'contain'

        backgroundImage.current.src = destiny.image
        backgroundImage.current.onload = () => {
            setImageLoaded(true)
        }
        backgroundImage.current.onerror = () => {
            backgroundImage.current.src = `${destiny.image}?t=${new Date().getTime()}`
        }
    }, [destiny, hasZoom])

    useEffect(() => {
        if (imageLoaded && size) {
            maxSizeRef.current = size.height

            if (!startRender.current) {
                startRender.current = true

                if (containerRef.current && canvasRef.current) {
                    const newCanvas = document.createElement('canvas')
                    containerRef.current.replaceChild(newCanvas, canvasRef.current)
                    canvasRef.current = newCanvas
                }

                _renderCanvas()
                startRender.current = false
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageLoaded, size])

    return (
        <Container ref={containerRef} className="canvas-container">
            <CanvasStyled ref={canvasRef} hasZoom={hasZoom} />
        </Container>
    )
})

export { Canvas }
