import { OrderProvider } from 'libs/contexts/order-context'
import React from 'react'
import { Outlet } from 'react-router-dom'

import { Container } from './main-layout.styled'

const MainLayout: React.FC = () => {
    return (
        <Container className="main-layout">
            <OrderProvider>
                <Outlet />
            </OrderProvider>
        </Container>
    )
}

export { MainLayout }
