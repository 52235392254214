import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: 80px;
    padding: 20px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        justify-content: center;
    }
`

export { Container }
