import styled from 'styled-components'

interface DefaultTextProps {
    variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'alert' | 'foregroundPrimary' | null
    light?: boolean
    bold?: boolean
    uppercase?: boolean
    weight?: string
    align?: 'left' | 'center' | 'right'
    dimensions?: 'large' | 'normal' | 'medium' | 'small' | 'xLarge' | 'xSmall' | 'xxLarge' | 'xxSmall'
    marginBottom?: number
    marginTop?: number
}
const DefaultText = styled.div<DefaultTextProps>`
    user-select: none;
    color: ${({ theme, variant, light }) => {
        if (variant) {
            return theme.colors[variant]
        }
        if (light) {
            return theme.colors.textLight
        }

        return theme.colors.text
    }};

    font-weight: ${({ bold, weight }) => weight ?? (bold ? '600' : 'normal')};
    text-align: ${({ align }) => align || 'left'};
    font-size: ${({ dimensions, theme }) =>
        dimensions ? theme.fontSizes[dimensions] : theme.fontSizes.normal}px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
    margin-top: ${({ marginTop }) => marginTop || 0}px;
`

export { DefaultText }
