import styled, { css } from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${({ theme }) => theme.colors.backgroundAccent};
`

export { Container }
