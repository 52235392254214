import americanas from 'assets/images/sales-channel/americanas-delivery.png'
import app from 'assets/images/sales-channel/app.png'
import azul from 'assets/images/sales-channel/azul-cargo.jpg'
import callCenter from 'assets/images/sales-channel/callcenter.png'
import dmLogo from 'assets/images/sales-channel/delivery-mall-avatar.png'
import eCommerce from 'assets/images/sales-channel/ecommerce.png'
import ifood from 'assets/images/sales-channel/ifood.png'
import james from 'assets/images/sales-channel/james.png'
import mercadoo from 'assets/images/sales-channel/mercadoo.svg'
import neemo from 'assets/images/sales-channel/neemo.png'
import pedZap from 'assets/images/sales-channel/pedzap.png'
import rappi from 'assets/images/sales-channel/rappi.png'
import supermenu from 'assets/images/sales-channel/supermenu.png'
import televendas from 'assets/images/sales-channel/televendas.png'
import uberEats from 'assets/images/sales-channel/ubereats.png'
import whatsapp from 'assets/images/sales-channel/whatsapp.png'
import zedelivery from 'assets/images/sales-channel/zedelivery.png'

const availableChannels: { [key: string]: string } = {
    'Call Center': callCenter,
    'James Delivery': james,
    Rappi: rappi,
    Supermenu: supermenu,
    Ifood: ifood,
    Televendas: televendas,
    UberEats: uberEats,
    App: app,
    'Zé Delivery': zedelivery,
    PedZap: pedZap,
    Whatsapp: whatsapp,
    'Ecommerce Próprio': eCommerce,
    'Delivery Mall': dmLogo,
    Neemo: neemo,
    'Americanas Delivery': americanas,
    'Azul Cargo': azul,
    Mercadoo: mercadoo,
}

function getSaleChannelIcon(channelName?: string): string {
    if (!channelName) return dmLogo

    return availableChannels[channelName]
}

export { getSaleChannelIcon }
