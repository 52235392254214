import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTheme } from 'styled-components'

import { useEventListener } from './use-event-listener'

function useIsMobileDevice() {
    const { devices } = useTheme()

    const [isMobile, setMobile] = useState<boolean>(true)

    const hasWindow = useMemo(() => {
        return typeof window !== 'undefined'
    }, [])

    const _getWindowDimensions = useCallback(() => {
        const tableWidth = Number(devices.tablet.replace(/\D/g, ''))
        const width = hasWindow ? window.innerWidth : 0
        const isMobile = width <= tableWidth

        setMobile(isMobile)
    }, [hasWindow, devices])

    const _handleResize = useCallback(() => {
        _getWindowDimensions()
    }, [_getWindowDimensions])

    useEventListener('resize', _handleResize)

    useEffect(() => {
        _getWindowDimensions()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return isMobile
}

export { useIsMobileDevice }
