import { useEffect, useRef } from 'react'

function useEventListener(
    eventName: string,
    handler: EventListener,
    element = typeof window !== 'undefined' ? window : null,
) {
    const savedHandler = useRef<EventListener>()

    useEffect(() => {
        savedHandler.current = handler
    }, [handler])

    useEffect(() => {
        const isSupported = element && element.addEventListener
        if (!isSupported) return

        const eventListener = (event: any) => {
            if (savedHandler.current) {
                savedHandler.current(event)
            }
        }

        element.addEventListener(eventName, eventListener)

        return () => {
            element.removeEventListener(eventName, eventListener)
        }
    }, [eventName, element])
}

export { useEventListener }
