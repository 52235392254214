import { memo } from 'react'

import { Container, LineOrange, Logo } from './footer.styled'

const FooterApp = memo(() => {
    return (
        <Container>
            <LineOrange />
            <Logo />
        </Container>
    )
})

export { FooterApp }
