import { IBagType } from 'types/bag'

function getOrderTypeLabel(type: IBagType | undefined): string {
    if (!type) return ''

    return {
        cargo: 'Cargo',
        delivery: 'Delivery',
        indoor: 'Praça de Alimentação',
        takeout: 'Balcão',
    }[type]
}

export { getOrderTypeLabel }
