import classNames from 'classnames'
import React, { memo, useMemo } from 'react'

import { DefaultText } from './text.styled'

interface DefaultTextProps {
    children?: any
    text?: string | null | number
    className?: string
    variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'alert' | 'foregroundPrimary' | null
    light?: boolean
    bold?: boolean
    weight?: string
    uppercase?: boolean
    align?: 'left' | 'center' | 'right'
    dimensions?: 'large' | 'normal' | 'medium' | 'small' | 'xLarge' | 'xSmall' | 'xxLarge' | 'xxSmall'
    marginBottom?: number
    marginTop?: number
}

const Text: React.FC<DefaultTextProps> = memo(({ children, className, text, ...props }) => {
    const value = useMemo(() => {
        if (typeof text === 'number') {
            return text.toString()
        }
        if (typeof children === 'number') {
            return children.toString()
        }

        return text || children
    }, [children, text])

    if (!value) {
        return null
    }
    return (
        <DefaultText className={classNames('text', className)} {...props}>
            {value}
        </DefaultText>
    )
})

export { Text }
