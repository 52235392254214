import iconSet from 'assets/logaroo-icon/selection.json'
import classNames from 'classnames'
import { useMemo } from 'react'
import IcoMoon, { IconProps } from 'react-icomoon'
import { useTheme } from 'styled-components'
// import { TSize, TVariant } from 'types/app'

// gerar tipagem
// console.log('Tipagem: ', `type IconNames = '${iconList(iconSet).join("' | '")}'`)
// export type TVariant = 'primary' | 'secondary' | 'danger' | 'success' | 'yellow' | 'foregroundPrimary' | null
// export type TSize = 'large' | 'normal' | 'medium' | 'small' | 'xLarge' | 'xSmall' | 'xxLarge' | 'xxSmall'

export type IconNames =
    | 'almonds'
    | 'chestnuts'
    | 'crustaceans'
    | 'dyes'
    | 'eggs'
    | 'fish'
    | 'gluten'
    | 'lactose'
    | 'milk'
    | 'soy'
    | 'arrow-left'
    | 'arrow-right'
    | 'chevron-left'
    | 'chevron-right'
    | 'chevron-up'
    | 'chevron-down'
    | 'card'
    | 'cutlery'
    | 'tray'
    | 'delivery-mall'
    | 'pix'
    | 'settings'
    | 'star-fill'
    | 'ticket'
    | 'balloon-question'
    | 'balloon-info'
    | 'bar-code'
    | 'bell'
    | 'book'
    | 'calendar'
    | 'cancel-order'
    | 'circle-arrow-right'
    | 'circle-check'
    | 'circle-plus'
    | 'circle-minus'
    | 'circle-question'
    | 'circle-refresh'
    | 'circle-x'
    | 'separete-2'
    | 'approach'
    | 'clock'
    | 'eye'
    | 'close-eye'
    | 'edit'
    | 'clip'
    | 'close'
    | 'expand'
    | 'columns'
    | 'copy'
    | 'upload'
    | 'download'
    | 'e-mail'
    | 'flag'
    | 'go-out'
    | 'identification'
    | 'import-order'
    | 'menu'
    | 'money'
    | 'real'
    | 'new-order'
    | 'note'
    | 'order'
    | 'performance'
    | 'phone'
    | 'pin-fill'
    | 'pin'
    | 'ranges'
    | 'rocket'
    | 'search-order'
    | 'detail-order'
    | 'edit-order'
    | 'search'
    | 'send'
    | 'separete'
    | 'shop'
    | 'tag-2'
    | 'tag-bag'
    | 'tag'
    | 'talk'
    | 'target'
    | 'trash'
    | 'truck'
    | 'user-check'
    | 'user'
    | 'van'
    | 'bike'
    | 'motorcycle'
    | 'car'
    | 'wallet'
    | 'x'

export type TSize = 'large' | 'normal' | 'medium' | 'small' | 'xLarge' | 'xSmall' | 'xxLarge' | 'xxSmall'
export type TVariant =
    | 'primary'
    | 'background'
    | 'backgroundAccent'
    | 'backgroundContrast'
    | 'backgroundHeader'
    | 'backgroundForm'
    | 'foregroundForm'
    | 'foregroundHeader'
    | 'foregroundPrimary'
    | 'card'
    | 'text'
    | 'border'
    | 'borderCheck'
    | 'borderForm'
    | 'secondary'
    | 'textLight'
    | 'disabled'
    | 'placeholder'
    | 'textButtonColor'
    | 'hover'
    | 'success'
    | 'danger'
    | 'alert'
    | 'shadow'
    | 'areaPrivate'
    | 'areaPublic'
    | 'areaOrder'

interface Props extends IconProps {
    icon: IconNames
    size?: TSize
    color?: string
    sizeNumber?: number | string
    variant?: TVariant
    style?: React.CSSProperties
}
const LogarooIcon: React.FC<Props> = ({
    variant,
    className,
    size,
    sizeNumber,
    color,
    onClick,
    style,
    ...props
}) => {
    const theme = useTheme()
    const styles = useMemo<React.CSSProperties>(() => {
        return {
            color: color || (variant ? theme.colors[variant] : theme.colors.text),
            width: sizeNumber || (size ? theme.fontSizes[size] : theme.fontSizes.normal),
            height: sizeNumber || (size ? theme.fontSizes[size] : theme.fontSizes.normal),
            cursor: onClick ? 'pointer' : undefined,
            ...style,
        }
    }, [theme, size, variant, color, sizeNumber, style, onClick])

    return (
        <IcoMoon
            className={classNames('icon', className)}
            style={styles}
            iconSet={iconSet}
            onClick={onClick}
            {...props}
        />
    )
}

export { LogarooIcon }
