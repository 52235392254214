import sourceAgent from 'assets/images/agent-marker.png'
import sourceStore from 'assets/images/public-mall.png'
import sourceMap from 'assets/images/test/mock-map.png'
import styled, { css } from 'styled-components'

const Container = styled.div<{ full?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
    flex: 1;

    .mapboxgl-popup-anchor-bottom {
        padding-bottom: 15px !important;
        .mapboxgl-popup-content {
            padding: 10px 20px;
            border-radius: 4px;
        }
        .mapboxgl-popup-close-button {
            font-size: 18px;
        }
    }
    ${({ full }) => full && css``}
`
const MockMap = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${sourceMap});
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(4px);
`

const IconStore = styled.img.attrs({ src: sourceStore })`
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: contain;
    background-color: rgba(0, 0, 0, 0.3);
`

const IconAgent = styled.img.attrs({ src: sourceAgent })`
    cursor: pointer;
    width: 32px;
    height: 32px;
    object-fit: contain;
`

const IconCustomer = styled.div`
    cursor: pointer;
    width: 25px;
    height: 25px;
    background-color: #f19738;
    border: 4px #fff solid;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.8);
`

export { Container, IconAgent, IconCustomer, IconStore, MockMap }
