import styled, { css } from 'styled-components'

const Container = styled.div`
    //padding: 20px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    border-radius: 0.75rem;

    @media (max-width: ${({ theme }) => theme.devices.tabletPro}) {
        canvas {
            width: 100%;
        }
    }
`
const CanvasStyled = styled.canvas<{ hasZoom?: boolean }>`
    padding: 0.5rem;

    ${({ hasZoom }) =>
        hasZoom &&
        css`
            padding: 0;
            width: 100%;
            height: 100%;
        `}
`

export { CanvasStyled, Container }
