import React, { cloneElement, FC, memo, useCallback, useEffect, useRef, useState } from 'react'

import { Container, TabContent, TabItem, TabItemRow } from './tabs-container.styled'

interface Props {
    tabs?: string[]
    children: React.ReactNode
}

const TabsContainer: FC<Props> = memo(({ tabs, children }) => {
    const tabsContainerRef = useRef<HTMLDivElement>(null)
    const [selectedTab, setSelectedTab] = useState<string>('tab-0')

    const _handleSelectTab = useCallback(
        (tab: string) => () => {
            setSelectedTab(tab)
        },
        [],
    )

    return (
        <Container ref={tabsContainerRef}>
            {!!tabs && tabs.length > 1 && (
                <TabItemRow width={tabsContainerRef.current?.clientWidth}>
                    {tabs?.map((tab, index) => (
                        <TabItem
                            key={index}
                            onClick={_handleSelectTab(`tab-${index}`)}
                            isActive={`tab-${index}` === selectedTab}
                        >
                            {tab}
                        </TabItem>
                    ))}
                </TabItemRow>
            )}
            {React.Children.map(children, (child, index) => {
                const childElement: React.ReactElement = child as React.ReactElement
                return (
                    <TabContent key={index} isActive={`tab-${index}` === selectedTab}>
                        {cloneElement(childElement, { ...childElement.props })}
                    </TabContent>
                )
            })}
        </Container>
    )
})

export { TabsContainer }
