import { memo } from 'react'

import { IconContainer, LoadingIcon, LoadingScreenContainer, LoadingText } from './loading.styled'

interface Props {
    show: boolean
    text?: boolean
}

const Loading: React.FC<Props> = memo(({ show, text }) => {
    if (!show) {
        return null
    }

    return (
        <LoadingScreenContainer>
            <IconContainer>
                <LoadingIcon />
            </IconContainer>
            <LoadingText>{text || 'Carregando'}</LoadingText>
        </LoadingScreenContainer>
    )
})

export { Loading }
