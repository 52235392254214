import { ModalProps } from 'libs/contexts/modal-context'
import { useModal } from 'libs/hooks'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

type TypeButton = 'ok' | 'yes' | 'no'

function useModalAppProviderController() {
    const modalShowRef = useRef<boolean>()
    const { loading, modals, removeModal } = useModal()
    const [visible, setVisible] = useState(false)
    const [show, setShow] = useState(false)
    const [currentModal, setCurrentModal] = useState<ModalProps | null>()
    const modal = useMemo(() => {
        if (modals.length > 0) {
            return modals[modals.length - 1]
        }
        return null
    }, [modals])

    const _onClose = useCallback(() => {
        if (modal) {
            if (modal.onClose) {
                modal.onClose()
            }
            removeModal(modal)
        }
    }, [modal, removeModal])

    const _onButtonClick = useCallback(
        async (type: TypeButton) => {
            if (modal) {
                if (type === 'yes') {
                    if (modal.onYesClick) {
                        await modal.onYesClick()
                    }
                }
                if (type === 'no') {
                    if (modal.onNoClick) {
                        await modal.onNoClick()
                    }
                }
                if (type === 'ok') {
                    if (modal.onConfirm) {
                        await modal.onConfirm()
                    }
                }
            }
            _onClose()
        },
        [modal, _onClose],
    )

    useEffect(() => {
        if (modal) {
            setCurrentModal(modal)
            setShow(true)
            setVisible(true)
            modalShowRef.current = true
        } else if (modalShowRef.current) {
            modalShowRef.current = false
            setShow(false)
            setTimeout(() => {
                setCurrentModal(null)
                setVisible(false)
            }, 400)
        }
    }, [modal])

    return {
        loading,
        visible,
        show,
        modal: currentModal,
        _onButtonClick,
    }
}

export { useModalAppProviderController }
