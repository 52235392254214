import styled from 'styled-components'

const Container = styled.div`
    background-color: ${({ theme }) => theme.colors.background};
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 16px;
    margin-bottom: 16px;
`

export { Container }
