import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div``

const RowInfo = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.625rem;
`

const StoresContainer = styled.div<{ isExpanded?: boolean; boxHeight?: string | number }>`
    display: flex;
    flex-direction: column;

    overflow: hidden;

    height: ${({ isExpanded, boxHeight }) => (isExpanded ? boxHeight : '0')}px;
    min-height: ${({ isExpanded, boxHeight }) => (isExpanded ? boxHeight : '0')}px;
    padding: 0;

    transition: all 500ms ease-in-out;
`

const ContainerChevron = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`

const LineChevron = styled.div`
    height: 1px;
    background-color: ${({ theme }) => theme.colors.text};
    flex: 1;
`

const ChevronIcon = styled(FontAwesomeIcon)`
    color: #fff;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.text};
    margin: 0px 10px;
`

export { ChevronIcon, Container, ContainerChevron, LineChevron, RowInfo, StoresContainer }
