import souceLogo from 'assets/images/logo-orange.png'
import styled from 'styled-components'

const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const LineOrange = styled.div`
    height: 2px;
    width: 90%;
    margin-top: 20px;
    background-color: ${({ theme }) => theme.colors.primary};
`

const Logo = styled.img.attrs({ src: souceLogo })`
    height: 30px;
    margin-top: 10px;
    object-fit: contain;
`

export { Container, LineOrange, Logo }
