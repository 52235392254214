import styled, { css } from 'styled-components'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
`

const TabItem = styled.div<{ isActive?: boolean }>`
    width: fit-content !important;
    display: flex;
    font-size: 0.875rem;
    font-weight: bold;
    padding: 0.5rem;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;

    ${({ isActive }) =>
        isActive
            ? css`
                  border-bottom: 0.063rem solid ${({ theme }) => theme.colors.primary};
              `
            : ''}
`

const TabItemRow = styled.div<{ width?: number }>`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: start;
    max-width: ${({ width }) => (width ? `${width - 20}px` : '100%')};
    overflow: hidden;
    overflow-x: auto;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        justify-content: center;
    }
`

const TabContent = styled.div<{ isActive?: boolean }>`
    display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
    position: relative;
    flex: 1;
    height: 100%;
`

export { Container, TabContent, TabItem, TabItemRow }
