import './index.css'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faChevronDown,
    faChevronUp,
    faLocationDot,
    faMotorcycle,
    faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { ModalAppProvider } from 'libs/contexts'
import { light } from 'libs/theme'
import { ModalApp } from 'modals'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from 'store'
import { ThemeProvider } from 'styled-components'

import { App } from './app'
import reportWebVitals from './reportWebVitals'

library.add(faChevronUp, faChevronDown, faTimes, faLocationDot, faMotorcycle)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={light}>
                <ModalAppProvider>
                    <App />
                    <ModalApp />
                </ModalAppProvider>
            </ThemeProvider>
        </PersistGate>
    </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
