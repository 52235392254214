import { AnyAction } from 'redux'
import { IAuth } from 'types/auth'

export const enum AuthReducerTypes {
    SET_AUTH = 'SET_AUTH',
    UPDATE_AUTH = 'UPDATE_AUTH',
    REMOVE_AUTH = 'REMOVE_AUTH',
}

export type AuthState = IAuth | null

export interface SetAction extends AnyAction {
    type: AuthReducerTypes.SET_AUTH
    payload: IAuth
}

export interface UpdateAction extends AnyAction {
    type: AuthReducerTypes.UPDATE_AUTH
    payload: IAuth
}
export interface RemoveAction extends AnyAction {
    type: AuthReducerTypes.REMOVE_AUTH
    payload: {}
}

export interface AuthReducerActions {
    setAuth(state: IAuth): SetAction
    updateAuth(state: IAuth): UpdateAction
    removeAuth(): RemoveAction
}
