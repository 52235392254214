import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { randomInt } from 'libs/helpers'
import { createContext, memo, useCallback, useReducer, useState } from 'react'

type VariantType = 'success' | 'danger' | 'alert'
type ModalType = 'alert' | 'confirmation'

export type ModalProps = {
    id?: number | string
    title?: string
    type?: ModalType
    variant?: VariantType
    message?: string
    iconName?: IconProp
    singleButtonText?: string
    yesButtonText?: string
    noButtonText?: string
    onClose?(): void
    onConfirm?(): void
    onYesClick?(): void
    onNoClick?(): void
}

const InitialValue = {
    loading: false,
    modals: [],
    setModal: () => {},
    setLoading: () => {},
    setAlertModal: () => {},
    setErrorModal: () => {},
    setConfirmationModal: () => {},
    removeModal: () => {},
}
export interface IModalAppContext {
    loading: boolean
    modals: ModalProps[]
    setModal(props: ModalProps): void
    setLoading(value: boolean): void
    setAlertModal(props: ModalProps): void
    setErrorModal(props: ModalProps): void
    setConfirmationModal(props: ModalProps): void
    removeModal(props: ModalProps): void
}

const ModalAppContext = createContext<IModalAppContext>(InitialValue)

enum ActionProvider {
    add = 'ADD',
    remove = 'REMOVE',
}

type Action = {
    type: ActionProvider
    payload: ModalProps
}

function reducer(prevState: ModalProps[], action: Action) {
    const { type, payload } = action
    switch (type) {
        case 'ADD':
            if (prevState.find(item => item.id === payload.id)) {
                return prevState
            }
            return [...prevState, payload]
        case 'REMOVE':
            return prevState.filter(item => item.id !== payload.id)
    }
    return []
}
type ModalAppProps = {
    children: any
}
const ModalAppProvider: React.FC<ModalAppProps> = memo(({ children }) => {
    const [loading, setLoading] = useState(false)
    const [modals, dispatch] = useReducer(reducer, [])

    const _setModal = useCallback(({ id, ...props }: ModalProps) => {
        dispatch({
            type: ActionProvider.add,
            payload: {
                id: id || randomInt(),
                ...props,
            },
        })
    }, [])

    const _setAlertModal = useCallback(({ id, iconName, type, ...props }: ModalProps) => {
        dispatch({
            type: ActionProvider.add,
            payload: {
                id: id || randomInt(),
                iconName: iconName || 'triangle-exclamation',
                type: type || 'alert',
                ...props,
            },
        })
    }, [])

    const _setErrorModal = useCallback(({ id, title, iconName, variant, type, ...props }: ModalProps) => {
        dispatch({
            type: ActionProvider.add,
            payload: {
                id: id || randomInt(),
                iconName: iconName || 'circle-xmark',
                title: title || 'Erro',
                variant: variant || 'danger',
                type: type || 'alert',
                ...props,
            },
        })
    }, [])

    const _setConfirmationModal = useCallback(({ id, type, ...props }: ModalProps) => {
        dispatch({
            type: ActionProvider.add,
            payload: {
                id: id || randomInt(),
                type: type || 'confirmation',
                ...props,
            },
        })
    }, [])

    const _removeModal = useCallback((modal: ModalProps) => {
        dispatch({
            type: ActionProvider.remove,
            payload: modal,
        })
    }, [])

    const _setLoading = useCallback((value: boolean) => {
        setLoading(value)
    }, [])
    return (
        <ModalAppContext.Provider
            value={{
                loading,
                modals,
                setModal: _setModal,
                setLoading: _setLoading,
                setAlertModal: _setAlertModal,
                setErrorModal: _setErrorModal,
                setConfirmationModal: _setConfirmationModal,
                removeModal: _removeModal,
            }}
        >
            {children}
        </ModalAppContext.Provider>
    )
})

export { ModalAppContext, ModalAppProvider }
