import useResizeObserver from '@react-hook/resize-observer'
import { useLayoutEffect, useState } from 'react'

const useResize = (target: any) => {
    const [size, setSize] = useState<DOMRectReadOnly>()

    // useLayoutEffect(() => {
    //     setSize(target.current.getBoundingClientRect())
    // }, [target])

    // Where the magic happens
    useResizeObserver(target, entry => setSize(entry.contentRect))
    return size
}

export { useResize }
