import styled from 'styled-components'

interface LinkProps {
    align?: 'left' | 'center' | 'right'
    marginBottom?: number
    marginTop?: number
}

const LinkText = styled.div<LinkProps>`
    cursor: pointer;
    -webkit-app-region: no-drag;
    user-select: none;
    width: fit-content;
    font-size: ${({ theme }) => theme.fontSizes.xxSmall}px;
    color: ${({ theme }) => theme.colors.primary};
    text-align: ${({ align }) => align || 'auto'};
    text-decoration: underline;
    text-decoration-style: solid;
    text-decoration-color: ${({ theme }) => theme.colors.primary};
    margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
    margin-top: ${({ marginTop }) => marginTop || 0}px;
`

export { LinkText }
