import { Info, Text } from 'components'
import { toCurrency } from 'libs/helpers/to-currency'
import { useOrder } from 'libs/hooks'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
    ChevronIcon,
    Container,
    ContainerChevron,
    LineChevron,
    RowInfo,
    StoresContainer,
} from './order-info.styled'

const OrderInfo = memo(() => {
    const { info, orders } = useOrder()

    const storeContainerRef = useRef<HTMLDivElement>(null)
    const [boxHeight, setBoxHeight] = useState<number>()

    const [isExpand, setIsExpand] = useState<boolean>(false)

    const _toggleExpand = useCallback(() => setIsExpand(state => !state), [])

    const _addBoxHeight = useCallback(() => {
        const stores = storeContainerRef.current?.children
        if (!stores) return
        const height = Array.from(stores)?.reduce((acc, item) => {
            const margin = window.getComputedStyle(item).getPropertyValue('margin-bottom').slice(0, -2)
            return acc + item.clientHeight + Number(margin)
        }, 0)

        setBoxHeight(height)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [info])

    useEffect(() => _addBoxHeight(), [_addBoxHeight])

    if (!info) {
        return null
    }

    return (
        <Container>
            <Text
                text="Informações de Pagamento"
                dimensions="medium"
                variant="primary"
                marginBottom={20}
                bold
            />
            <StoresContainer ref={storeContainerRef} isExpanded={isExpand} boxHeight={boxHeight}>
                {orders.map((item, i) => (
                    <RowInfo key={i}>
                        <Text text={item.store.name} bold dimensions="medium" />
                        <Text text={toCurrency(item.value)} bold dimensions="medium" />
                    </RowInfo>
                ))}
            </StoresContainer>
            <RowInfo>
                <Text text="Forma de Pagamento" dimensions="xSmall" />
                <Text text={info.payment} dimensions="xSmall" />
            </RowInfo>
            {!!info.subtotal && info.subtotal !== info.total && (
                <RowInfo>
                    <Text text="Subtotal" dimensions="xSmall" />
                    <Text text={info.subtotal} dimensions="xSmall" />
                </RowInfo>
            )}
            {!!info.service_tax && (
                <RowInfo>
                    <Text text="Serviço" dimensions="xSmall" />
                    <Text text={info.service_tax} dimensions="xSmall" />
                </RowInfo>
            )}
            {!!info.delivery_fee && (
                <RowInfo>
                    <Text text="Entrega" dimensions="xSmall" />
                    <Text text={info.delivery_fee} dimensions="xSmall" />
                </RowInfo>
            )}
            {!!info.total && (
                <RowInfo>
                    <Text text="Total" dimensions="xSmall" />
                    <Text text={info.total} dimensions="xSmall" />
                </RowInfo>
            )}

            <ContainerChevron onClick={_toggleExpand}>
                <LineChevron />
                <ChevronIcon icon={isExpand ? 'chevron-up' : 'chevron-down'} />
                <LineChevron />
            </ContainerChevron>
        </Container>
    )
})

export { OrderInfo }
