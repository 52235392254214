function toCurrency(value: number) {
    let curreny = 'R$'
    if (value) {
        curreny += value.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            currency: 'BRL',
        })
    } else {
        curreny += '0,00'
    }

    return curreny
}

export { toCurrency }
