import { MainLayout, PageLayout } from 'layouts'
import { useStart } from 'libs/hooks'
import { HomePage } from 'pages'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'

const Router: React.FC = memo(() => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<MainLayout />}>
                    <Route path="/" element={<PageLayout />}>
                        <Route index element={<HomePage />} />
                        <Route path=":code" element={<HomePage />} />
                        <Route path="bag/:bagCode" element={<HomePage />} />
                    </Route>
                </Route>
            </Routes>
        </BrowserRouter>
    )
})

export { Router }
