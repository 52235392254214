import { format, parseISO } from 'date-fns'
import { getOrderTypeLabel } from 'libs/helpers/get-order-type-label'
import { toCurrency } from 'libs/helpers/to-currency'
import { useOrder } from 'libs/hooks'
import { useEchoConnection } from 'libs/hooks/use-echo-connection'
import { OrderChangeObservable } from 'libs/observables'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { IModerationOrder } from 'types/bag'
import { IDMOrder, IOrder } from 'types/order'
import { ITimes } from 'types/times'

type Step = {
    name: string
    time?: string | null
    raw?: Date | null
    active?: boolean
    fail?: boolean
    show_step?: boolean
}
type Params = {
    o?: IOrder
    m?: IModerationOrder
}
function useCardOrder({ o, m }: Params) {
    const { info: ordersInfo } = useOrder()
    const { order } = useListenerOrder(o)
    const { moderationOrder } = useListenerModerationOrder(m)
    const [expand, setExpand] = useState(false)

    const store = useMemo(() => {
        if (order) {
            return order.store
        }
        if (moderationOrder) {
            return moderationOrder.store
        }
    }, [order, moderationOrder])

    const items = useMemo(() => {
        if (order) {
            return order.items
        }
        if (moderationOrder) {
            return moderationOrder.items
        }

        return []
    }, [order, moderationOrder])

    const info = useMemo(() => {
        if (order) {
            return `Pedido: ${order.code}`
        }
        if (moderationOrder) {
            return `Pedido: ${moderationOrder.code}`

            // return `${moderationOrder.code}`.toUpperCase()
        }
    }, [order, moderationOrder])

    const value = useMemo(() => {
        if (order) {
            return toCurrency(Number(order.value))
        }
        if (moderationOrder) {
            return toCurrency(Number(moderationOrder.value))
        }

        return toCurrency(0)
    }, [order, moderationOrder])

    const delivered = useMemo(() => {
        return !!order?.dm_order?.times?.at_client
    }, [order?.dm_order?.times?.at_client])

    const orderType = useMemo(() => ordersInfo?.type, [ordersInfo])

    const stepsCountByType = useMemo(() => {
        if (!orderType) return 0

        return {
            cargo: 4,
            delivery: 6,
            takeout: 2,
            indoor: 4,
        }[orderType]
    }, [orderType])

    const stepsByOrderTypes = useMemo((): Step[] => {
        const times: ITimes | undefined = order?.dm_order?.times

        if (!times) {
            return []
        }

        if (orderType === 'takeout') {
            return [
                {
                    name: 'Seu pedido foi entregue! Até a próxima ;)',
                    time: times.end_delivery && format(parseISO(times.end_delivery), 'HH:mm'),
                    raw: parseISO(times.end_delivery),
                    show_step: true,
                },
            ]
        }

        if (orderType === 'indoor') {
            return [
                {
                    name: 'Seu pedido foi coletado',
                    time: times.collected_at && format(parseISO(times.collected_at), 'HH:mm'),
                    raw: parseISO(times.collected_at),
                    show_step: true,
                },
                {
                    name: 'Seu pedido está a caminho',
                    time: times.start_delivery && format(parseISO(times.start_delivery), 'HH:mm'),
                    raw: parseISO(times.start_delivery),
                    show_step: true,
                },
                {
                    name: 'Seu pedido foi entregue! Até a próxima ;)',
                    time: times.end_delivery && format(parseISO(times.end_delivery), 'HH:mm'),
                    raw: parseISO(times.end_delivery),
                    show_step: true,
                },
            ]
        }

        // Delivery
        return [
            {
                name: 'Seu pedido foi coletado',
                time: times.collected_at && format(parseISO(times.collected_at), 'HH:mm'),
                raw: parseISO(times.collected_at),
                show_step: true,
            },
            {
                name: 'Aguardando entregador',
                time: times.routed_at && format(parseISO(times.routed_at), 'HH:mm'),
                raw: parseISO(times.routed_at),
                show_step: true,
            },
            {
                name: 'Prepare-se! Sua entrega está próxima',
                time: times.start_delivery && format(parseISO(times.start_delivery), 'HH:mm'),
                raw: parseISO(times.start_delivery),
                show_step: true,
            },
            {
                name: 'O entregador chegou',
                time: times.end_delivery && format(parseISO(times.end_delivery), 'HH:mm'),
                raw: parseISO(times.end_delivery),
                show_step: true,
            },
            {
                name: 'Seu pedido foi entregue! Até a próxima ;)',
                time: times.at_client && format(parseISO(times.at_client), 'HH:mm'),
                raw: parseISO(times.at_client),
                show_step: true,
            },
        ]
    }, [orderType, order])

    const steps = useMemo(() => {
        const steps: Step[] = []

        const times = order?.dm_order?.times

        if (order && times && !Object.values(times).find(item => typeof item === 'string')) {
            steps.push(
                {
                    name: 'Pedido enviado para a loja',
                    time: format(parseISO(order.created_at), 'HH:mm'),
                    raw: parseISO(order.created_at),
                    show_step: true,
                },
                {
                    name: 'Aguardando aprovação da loja',
                    time: format(parseISO(order.created_at), 'HH:mm'),
                    raw: parseISO(order.created_at),
                    show_step: false,
                },
            )
            if (order.status === false) {
                steps.push({
                    name: 'Cancelado pela Loja',
                    time: format(parseISO(order.updated_at), 'HH:mm'),
                    raw: parseISO(order.updated_at),
                    fail: true,
                    show_step: true,
                })
            }

            steps.push(
                ...Array.from<Step, Step>({ length: stepsCountByType }, () => ({
                    name: '',
                    time: undefined,
                    raw: undefined,
                    show_step: true,
                })),
            )
            return steps
        }

        if (times) {
            steps.push({
                name: 'Seu pedido está sendo preparado',
                time: times.start_production && format(parseISO(times.start_production), 'HH:mm'),
                raw: parseISO(times.start_production),
                show_step: true,
            })
            steps.push({
                name: 'Seu pedido está pronto para coleta',
                time: times.end_production && format(parseISO(times.end_production), 'HH:mm'),
                raw: parseISO(times.end_production),
                show_step: true,
            })

            steps.push(...stepsByOrderTypes)
        }

        steps.sort((a, b) => {
            if (a.raw && b.raw) {
                return a.raw.getTime() - b.raw.getTime()
            }
            return 0
        })

        return steps
    }, [order, stepsByOrderTypes, stepsCountByType])

    const stepsComplete = useMemo(() => {
        const completes = steps.filter(item => item.time).reverse()
        if (completes.length > 0 && !order?.dm_order?.times?.canceled_at) {
            completes[0].active = true
        }

        return completes
    }, [steps, order])

    const _toggleExpand = useCallback(() => {
        setExpand(state => !state)
    }, [])

    return { store, items, info, value, delivered, steps, stepsComplete, expand, _toggleExpand }
}

function useListenerOrder(currentOrder?: IOrder) {
    const [hash, setHash] = useState<string>()
    const [order, setOrder] = useState<IOrder>()

    const enable = useMemo(() => {
        return !order?.dm_order?.times?.at_client
    }, [order])

    const socketEvents = useMemo(() => {
        return [
            {
                name: '.tracker',
                callback: (order: IDMOrder) => {
                    OrderChangeObservable.next({ ...currentOrder, dm_order: order } as IOrder)
                    setOrder({ ...currentOrder, dm_order: order } as IOrder)
                },
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setOrder(currentOrder)
        setHash(currentOrder?.dm_order?.hash)
    }, [currentOrder])

    useEchoConnection({
        code: hash,
        enable,
        channelName: hash ? `tracker.${hash}` : null,
        events: socketEvents,
    })

    return { order }
}
function useListenerModerationOrder(currentOrderModerationOrder?: IModerationOrder) {
    const [moderationOrder, setModerationOrder] = useState<IModerationOrder>()

    const socketEvents = useMemo(() => {
        return [
            {
                name: '.tracker',
                callback: (order: IModerationOrder) => {
                    setModerationOrder(order)
                },
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setModerationOrder(currentOrderModerationOrder)
    }, [currentOrderModerationOrder])

    useEchoConnection({
        code: (moderationOrder?.id || 0).toString(),
        enable: !!moderationOrder?.id,
        channelName: moderationOrder?.id ? `moderation.order.${moderationOrder.id}` : null,
        events: socketEvents,
    })

    return { moderationOrder }
}

export { useCardOrder }
