import Axios from 'axios'
import LaravelEcho from 'laravel-echo'
import { BASE_URL, WS_URL } from 'libs/environment'
import { formatResponseError } from 'libs/helpers/format-response-error'
import socketIOClient from 'socket.io-client'
import { IErrorResponse } from 'types/error-response'

const baseURL = BASE_URL

function echoConnection() {
    const connection = new LaravelEcho({
        host: WS_URL,
        broadcaster: 'socket.io',
        client: socketIOClient,
        namespace: '.',
    })

    return connection
}

const axios = Axios.create({
    baseURL,
    timeout: 16000,
})

axios.interceptors.request.use(config => {
    if (config.headers) {
        config.headers['Content-Type'] = 'application/json'
        config.headers.Accept = 'application/json'

        // const token = store.getState().auth?.token
        // if (token) {
        //     config.headers.Authorization = `Bearer ${token}`
        // }
    }

    return config
})

axios.interceptors.response.use(
    response => {
        const message = response.data?.message
        if (message) {
            return { ...response.data.data, message }
        }
        return response.data.data
    },
    error => {
        const status = error?.response?.status || 0
        return Promise.reject({ message: formatResponseError(error), status } as IErrorResponse)
    },
)

export { axios, baseURL, echoConnection }
