import SourceBox from 'assets/images/box.png'
import styled, { css } from 'styled-components'

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${({ theme }) => theme.elevation.tier5};
    display: flex;
    align-items: center;
    justify-content: center;
`
const Background = styled.div<{ isEffectActive?: boolean }>`
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
`

const Box = styled.div<{ show?: boolean }>`
    z-index: ${({ theme }) => theme.elevation.tier1};
    width: 100%;
    max-width: 350px;
    min-height: 200px;
    background-color: ${({ theme }) => theme.colors.background};
    overflow: hidden;
    transition: 400ms;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: ${({ theme }) => theme.dimensions.radius}px;
    border: solid 2px ${({ theme }) => theme.colors.primary};
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({ show }) =>
        show
            ? css`
                  animation-name: show;
                  animation-duration: 400ms;
              `
            : css`
                  animation-name: close;
                  animation-duration: 450ms;
              `};
`

const Header = styled.div`
    background-color: ${({ theme }) => theme.colors.primary};
    padding: 40px 40px 0px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Square = styled.div`
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.primary};
    transform: rotate(45deg) translateX(10px) translateY(10px);
    transform-origin: 50% 50%;
`

const Image = styled.img.attrs({ src: SourceBox })`
    height: 100px;
    object-fit: contain;
    margin: 40px 0px;
`

export { Background, Box, Container, Header, Image, Square }
