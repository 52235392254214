import { Button } from 'components'
import { Loading } from 'modals/loading/loading'
import { memo } from 'react'

import { useModalAppProviderController } from './modal-app.controller'
import { Box, Container, ContainerButtons, Footer, Icon, Message, Separator, Title } from './modal-app.styled'

const ModalApp = memo(() => {
    const { loading, visible, show, modal, _onButtonClick } = useModalAppProviderController()

    return (
        <>
            {visible && (
                <Container id="global-modal" visible={visible}>
                    <Box show={show}>
                        {!!modal && (
                            <>
                                {!!modal.iconName && <Icon icon={modal.iconName} variant={modal.variant} />}
                                {!!modal.title && <Title id="title-modal">{modal.title}</Title>}
                                {!!modal.message && <Message id="message-modal">{modal.message}</Message>}
                                <Footer>
                                    {modal.type === 'confirmation' ? (
                                        <>
                                            <ContainerButtons>
                                                <Button
                                                    id="yes-button"
                                                    title={modal.yesButtonText || 'Sim'}
                                                    variant="success"
                                                    onClick={() => _onButtonClick('yes')}
                                                />
                                            </ContainerButtons>
                                            <Separator />
                                            <ContainerButtons>
                                                <Button
                                                    id="no-button"
                                                    title={modal.singleButtonText || 'Não'}
                                                    variant="danger"
                                                    onClick={() => _onButtonClick('no')}
                                                />
                                            </ContainerButtons>
                                        </>
                                    ) : (
                                        <ContainerButtons>
                                            <Button
                                                id="single-button"
                                                title={modal.singleButtonText || 'OK'}
                                                onClick={() => _onButtonClick('ok')}
                                            />
                                        </ContainerButtons>
                                    )}
                                </Footer>
                            </>
                        )}
                    </Box>
                </Container>
            )}
            <Loading show={loading} />
        </>
    )
})

export { ModalApp }
