import styled, { css } from 'styled-components'

export interface ButtonProps {
    transparent?: boolean
    outline?: boolean
    disabled?: boolean
    position?: 'left' | 'right'
    withoutBorder?: boolean
    widthFitContainer?: boolean
    variant?: 'secondary' | 'white' | 'danger' | 'success' | 'alert' | 'text-only'
    uppercase?: boolean
    marginBottom?: boolean
}

const Container = styled.button<ButtonProps>`
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.dimensions.radius}px;
    border-style: solid;
    height: ${({ theme }) => theme.dimensions.heightButton}px;
    width: ${({ widthFitContainer }) => (widthFitContainer ? 'auto' : '100%')};
    margin-bottom: ${({ marginBottom }) => (marginBottom ? 10 : 0)}px;
    ${({ outline, theme, withoutBorder }) =>
        outline
            ? css`
                  border-width: ${withoutBorder ? 0 : theme.dimensions.borderWidth}px;
              `
            : css`
                  border-width: 0px;
              `}
    ${({ outline, variant, disabled }) =>
        !outline &&
        !(variant === 'white') &&
        !(variant === 'text-only') &&
        !disabled &&
        css`
            shadow-color: #000;
            shadow-offset: 0px 1px;
            shadow-opacity: 0.2;
            shadow-radius: 3.34px;
            elevation: 3;
        `}
    ${({ theme, variant, outline, disabled }) => {
        if (disabled) {
            return css`
                background-color: ${`${theme.colors.disabled}4D`};
                border-color: ${'#ffffff'};
            `
        }

        if (variant === 'secondary') {
            return css`
                background-color: ${outline ? 'transparent' : theme.colors.secondary};
                border-color: ${theme.colors.secondary};
            `
        }
        if (variant === 'success') {
            return css`
                background-color: ${outline ? 'transparent' : theme.colors.success};
                border-color: ${theme.colors.success};
            `
        }

        if (variant === 'danger') {
            return css`
                background-color: ${outline ? 'transparent' : theme.colors.danger};
                border-color: ${theme.colors.danger};
            `
        }
        if (variant === 'alert') {
            return css`
                background-color: ${outline ? 'transparent' : theme.colors.alert};
                border-color: ${theme.colors.danger};
            `
        }

        if (variant === 'white') {
            return css`
                background-color: ${outline ? 'transparent' : '#ffffff'};
                border-color: ${'#ffffff'};
            `
        }

        if (variant === 'text-only') {
            return css``
        }

        return css`
            background: ${outline ? 'transparent' : theme.colors.primary};
            border-color: ${theme.colors.primary};
            &:hover {
                background-color: ${({ theme }) => theme.colors.primary};
                ${Title} {
                    color: #fff;
                }
            }
        `
    }};
    ${({ position }) => {
        if (position === 'left') {
            return css`
                align-self: flex-start;
            `
        }
        if (position === 'right') {
            return css`
                align-self: flex-end;
            `
        }
        return css`
            align-self: center;
        `
    }};
`

const Title = styled.div<ButtonProps>`
    margin: 0px 20px;
    flex: 1;
    font-weight: bold;
    letter-spacing: 1.25px;
    text-align: center;
    text-transform: none;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
    ${({ theme, outline, variant, transparent }) => {
        if (transparent) {
            return css`
                color: transparent;
            `
        }
        if (variant === 'secondary') {
            return css`
                color: ${outline ? theme.colors.secondary : theme.colors.textButtonColor};
            `
        }
        if (variant === 'success') {
            return css`
                color: ${outline ? theme.colors.success : theme.colors.textButtonColor};
            `
        }
        if (variant === 'danger') {
            return css`
                color: ${outline ? theme.colors.danger : theme.colors.textButtonColor};
            `
        }

        if (variant === 'alert') {
            return css`
                color: ${outline ? theme.colors.alert : theme.colors.textButtonColor};
            `
        }
        if (variant === 'white') {
            return css`
                color: ${theme.colors.primary};
            `
        }

        if (variant === 'text-only') {
            return css`
                color: ${theme.colors.primary};
            `
        }

        return css`
            color: ${outline ? theme.colors.primary : theme.colors.textButtonColor};
        `
    }};
`

const ContainerLoading = styled.div<ButtonProps>`
    flex: 1;
    position: absolute;
    align-items: center;
    justify-content: center;
    .lds-ring {
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
    }
    .lds-ring div {
        display: block;
        position: absolute;
        width: 25px;
        height: 25px;
        border: 3px solid
            ${({ theme, variant }) => {
                if (variant && ['white', 'text-only'].includes(variant)) {
                    return theme.colors.primary
                }
                return theme.colors.textButtonColor
            }};
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${({ theme, variant }) => {
                if (variant && ['white', 'text-only'].includes(variant)) {
                    return theme.colors.primary
                }
                return theme.colors.textButtonColor
            }}
            transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
    }
    @keyframes lds-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

export { Container, ContainerLoading, Title }
