import { forwardRef, memo } from 'react'

import { Container } from './scroll.styled'

const Scroll = memo(
    forwardRef<any, any>(({ children }, ref) => {
        return (
            <Container ref={ref} className="scroll">
                {children}
            </Container>
        )
    }),
)

export { Scroll }
