import { ReactNode } from 'react'

import { Container } from './card.styled'

type Props = {
    children?: ReactNode
}

const Card: React.FC<Props> = ({ children }) => {
    return <Container>{children}</Container>
}

export { Card }
