import { createActions, createReducer } from 'reduxsauce'

import {
    AuthReducerActions,
    AuthReducerTypes,
    AuthState,
    SetAction,
    UpdateAction,
} from '../redux-types/auth-reducer-type'

const INITIAL_STATE: AuthState = null

export const {
    Creators: { setAuth, updateAuth, removeAuth },
} = createActions<AuthReducerActions, AuthReducerActions>({
    setAuth: ['payload'],
    updateAuth: ['payload'],
    removeAuth: [],
})

function set(state: AuthState, action: SetAction): any {
    return action.payload
}

function update(state: AuthState, action: UpdateAction): any {
    if (state) {
        return { ...state, ...action.payload }
    }
    return action.payload
}

function remove(): any {
    return INITIAL_STATE
}

const reducers = createReducer(INITIAL_STATE, {
    [AuthReducerTypes.SET_AUTH]: set,
    [AuthReducerTypes.UPDATE_AUTH]: update,
    [AuthReducerTypes.REMOVE_AUTH]: remove,
})
export default reducers
