import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`

export { Container, Content }
