import './app.css'

import { Router } from 'routes/routes'

const App = () => {
    return (
        <div className="app">
            <Router />
        </div>
    )
}

export { App }
