import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import sourceLogo from 'assets/images/logo.png'
import styled, { css } from 'styled-components'

const Container = styled.div`
    flex: 1;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    .scroll {
        padding: 20px;
    }

    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        max-width: none;
    }
`

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`

const ContainerMall = styled.div`
    display: flex;
    align-items: center;
`

const Logo = styled.img`
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 10px;
    border-radius: 50%;
`

const ContainerInfoMall = styled.div`
    flex: 1;
`

const MapHeaderContainer = styled.div`
    width: 95%;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.background};
    border: 0.063rem solid ${({ theme }) => theme.colors.border};
`

const LocationIcon = styled(FontAwesomeIcon).attrs({
    icon: 'location-dot',
})`
    height: 0.75rem;
    width: 0.75rem;
`

const ContainerMap = styled.div<{ full?: boolean }>`
    width: 100%;
    position: relative;
    //max-width: calc(100vw - 80px);
    height: 250px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.background};
    .map {
        flex: 1;
        border-radius: 16px;
        overflow: hidden;
    }

    .button {
        position: absolute;
        bottom: 0.5rem;
        width: 80%;
    }

    ${MapHeaderContainer} {
        position: absolute;
        top: 0.8rem;
    }

    ${({ full }) =>
        full &&
        css`
            top: 0;
            left: 0;
            width: 100vw;
            height: 100%;
            position: fixed;
            .map {
                border-radius: 0px;
            }
        `}
`

const MapContent = styled.div`
    display: flex;
    position: relative;
`

const HeaderMap = styled.div`
    height: 60px;
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.background};
`

const CloseIcon = styled(FontAwesomeIcon).attrs({ icon: 'times' })`
    color: ${({ theme }) => theme.colors.primary};
    font-size: 30px;
    cursor: pointer;
`

export {
    CloseIcon,
    Container,
    ContainerInfoMall,
    ContainerMall,
    ContainerMap,
    Content,
    HeaderMap,
    LocationIcon,
    Logo,
    MapContent,
    MapHeaderContainer,
}
