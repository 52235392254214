import { IAddress } from 'types/address'

function formatAddress(address?: IAddress): string {
    if (!address) return ''

    const { street, number, neighborhood, complement } = address

    return `${street}, ${number} - ${neighborhood}, ${complement || ''}`
}

export { formatAddress }
