import { useOrder } from 'libs/hooks'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

function useOrderWay() {
    const hasShowRef = useRef(false)
    const modalShowRef = useRef<boolean>(false)
    const { orders } = useOrder()

    const order = useMemo(() => {
        if (orders.length > 0) {
            return orders[0]
        }
        return undefined
    }, [orders])

    const [show, setShow] = useState(false)
    const [visible, setVisible] = useState(false)

    const _show = useCallback(() => {
        setShow(true)
    }, [])

    const _close = useCallback(() => {
        setShow(false)
    }, [])

    useEffect(() => {
        if (show) {
            setVisible(true)
            modalShowRef.current = true
        } else if (modalShowRef.current) {
            modalShowRef.current = false
            setTimeout(() => {
                setVisible(false)
            }, 400)
        }
    }, [show])

    useEffect(() => {
        if (order?.dm_order?.times?.start_delivery && !order?.dm_order?.times.end_delivery) {
            if (!hasShowRef.current) {
                setShow(true)
            }
            hasShowRef.current = true
            setTimeout(() => {
                setShow(false)
            }, 5000)
        }
    }, [order])

    return { show, visible, _show, _close }
}

export { useOrderWay }
