import { DefaultTheme } from 'styled-components'

const light: DefaultTheme = {
    dark: false,
    colors: {
        primary: '#f16c07',
        background: '#FFFFFF',
        backgroundAccent: '#F5F5F5',
        backgroundContrast: '#D9DDE8',
        backgroundHeader: '#f16c07',
        backgroundForm: '#FFFFFF',
        foregroundForm: '#949393',
        foregroundHeader: '#FFFFFF',
        foregroundPrimary: '#FFFFFF',
        card: '#FFFFFF',
        text: '#2D2D2D',
        textLight: '#666666',
        border: '#D9DDE8',
        borderCheck: '#999999',
        borderForm: '#ACACAC',
        secondary: '#FF8E21',
        disabled: '#969696',
        placeholder: 'rgba(0, 0, 0, 0.3)',
        textButtonColor: '#ffffff',
        hover: 'rgba(0, 0, 0, 0.1)',
        success: '#038D24',
        danger: '#FA2D37',
        alert: '#FFBC32',
        shadow: '#000000',
        areaPublic: '#F55500',
        areaPrivate: '#199CFF',
        areaOrder: '#6ab04c',
    },
    dimensions: {
        heightButton: 38,
        heightForm: 50,
        headerHeight: 64,
        borderWidth: 1,
        paddingForm: 6,
        radius: 8,
        radiusForm: 8,
        maxWidthContent: 1000,
    },
    fontSizes: {
        large: 18,
        medium: 16,
        normal: 14,
        small: 10,
        xLarge: 20,
        xSmall: 12,
        xxLarge: 22,
        xxSmall: 14,
    },
    devices: {
        mobile: '320px',
        tablet: '720px',
        tabletPro: '1020px',
        desktop: '1200px',
        desktopLarge: '1600px',
        desktopFull: '1920px',
    },
    fonts: {
        barlow: 'Barlow',
    },
    elevation: {
        tier1: 1,
        tier2: 2,
        tier3: 3,
        tier4: 4,
        tier5: 10,
        modal: 100,
        loading: 101,
    },
}

export { light }
