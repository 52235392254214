import { store } from 'store'

function formatResponseError(err: any) {
    let msg = ''

    if (err?.response) {
        try {
            const {
                data,
                status,
                config: { headers },
            } = err.response

            if (status === 501) {
                return 'Funcionalidade não implementada.'
            }

            if (status > 500) {
                return 'Algo estranho aconteceu! Mas não se preocupe pois nossa equipe já está verificando.'
            }

            if (status === 401 && headers.Authorization) {
                return 'Sem autorização'
            }

            if (data.errors) {
                const errors = Object.keys(data.errors)

                errors.forEach((element, i) => {
                    if (i !== errors.length - 1) {
                        msg += `${data.errors[element][0]}\n`
                    } else {
                        msg += `${data.errors[element][0]}`
                    }
                })
            } else if (data.message) {
                msg = data.message
            } else if (data.data) {
                msg = data.data
            } else if (data.error) {
                msg = data.error
            } else {
                const errors = Object.keys(data)
                errors.forEach((element, i) => {
                    data[element].forEach((e: any) => {
                        if (i !== errors.length - 1) {
                            msg += `${e}\n`
                        } else {
                            msg += `${e}`
                        }
                    })
                })
            }
        } catch (error) {}
    }

    return (
        msg ||
        'Não foi possível conectar com nossos servidores, verifique a sua conexão de rede ou entre em contato com o suporte.'
    )
}

export { formatResponseError }
