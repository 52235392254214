import { Header } from 'components'
import React from 'react'
import { Outlet } from 'react-router-dom'

import { Container, Content } from './page-layout.styled'

const PageLayout: React.FC = () => {
    return (
        <Container className="page-layout">
            {/*
                <Header />
            */}
            <Content>
                <Outlet />
            </Content>
        </Container>
    )
}

export { PageLayout }
