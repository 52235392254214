import { ModalAppContext } from 'libs/contexts'
import { useContext } from 'react'

function useModal() {
    const context = useContext(ModalAppContext)

    return context
}

export { useModal }
