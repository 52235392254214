import { Canvas, Map, PageContainer, Scroll, Text } from 'components'
import { TabsContainer } from 'components/tabs-container/tabs-container'
import { formatAddress } from 'libs/helpers/format-address'
import { useOrder } from 'libs/hooks'
import { useIsMobileDevice } from 'libs/hooks/use-window-dimensions'
import { OrderWay } from 'modals'
import { useMemo } from 'react'

import { Container, Content, LocationIcon, MapContainer, MapContent, MapHeaderInfo } from './home-page.styled'
import { LeftOrderInfo } from './widgets'

const HomePage = () => {
    const { info, saleDetails, isPresential } = useOrder()
    const isMobile = useIsMobileDevice()

    const titleCard = useMemo(() => {
        return saleDetails?.type === 'takeout' ? 'Local de Retirada' : 'Local de Entrega'
    }, [saleDetails])

    const mapTabs = useMemo(() => {
        const tabs: string[] = []
        info?.details?.destinies.forEach(item => {
            const stores = item.maps.map(map => {
                return map?.store?.name
            })
            tabs.push(...stores)
        })
        return tabs
    }, [info])

    return (
        <PageContainer>
            <Container>
                <LeftOrderInfo />
                {!isMobile && (
                    <Content className="content-map">
                        <MapContainer>
                            <Text text={titleCard} marginBottom={8} dimensions="medium" variant="primary" />
                            {isPresential && info?.details?.destinies?.length ? (
                                <TabsContainer tabs={mapTabs}>
                                    {info?.details?.destinies.map((destiny, index) =>
                                        destiny.maps.map(map => (
                                            <MapContent key={index}>
                                                <MapHeaderInfo isAbsolute={false}>
                                                    <LocationIcon />
                                                    <Text
                                                        text={`${destiny?.name} - ${map.name}`}
                                                        dimensions="medium"
                                                    />
                                                </MapHeaderInfo>
                                                <Canvas destiny={{ ...destiny, maps: [map] }} />
                                            </MapContent>
                                        )),
                                    )}
                                </TabsContainer>
                            ) : (
                                <MapContent>
                                    <MapHeaderInfo isAbsolute>
                                        <LocationIcon />
                                        <Text text={formatAddress(info?.address)} dimensions="medium" />
                                    </MapHeaderInfo>
                                    <Map />
                                </MapContent>
                            )}
                        </MapContainer>
                    </Content>
                )}
            </Container>
            <OrderWay />
        </PageContainer>
    )
}

export { HomePage }
