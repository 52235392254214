import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Container = styled.div`
    flex: 1;
    display: flex;

    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        .content-map {
            display: none;
        }
    }
`

const Content = styled.div`
    //height: calc(100vh - 80px);
    flex: 1;
    display: flex;
    padding: 1.25rem;
`

const ContentOrderInfo = styled.div`
    display: none;
    margin: 20px;
    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        display: block;
    }
`

const LocationIcon = styled(FontAwesomeIcon).attrs({
    icon: 'location-dot',
})`
    height: 0.875rem;
    width: 0.875rem;
`

const MapContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    position: relative;
    padding: 0.875rem;

    border-radius: 0.875rem;

    background-color: ${({ theme }) => theme.colors.background};
`

const MapHeaderInfo = styled.div<{ isAbsolute?: boolean }>`
    width: calc(100% - 1rem);

    display: flex;
    align-items: center;
    gap: 0.5rem;

    position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'relative')};
    margin-bottom: ${({ isAbsolute }) => (isAbsolute ? '0rem' : '0.5rem')};
    top: 0.5rem;
    left: 0.5rem;
    padding: 0.875rem;

    border-radius: 0.5rem;

    background-color: ${({ theme }) => theme.colors.background};

    z-index: ${({ theme }) => theme.elevation.tier5};
    border: 0.126rem solid ${({ theme }) => theme.colors.border};
`

const MapContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.5rem;
    position: relative;
    overflow: hidden;

    border-radius: 0.875rem;

    //  border: 0.063rem solid ${({ theme }) => theme.colors.border};
`

export { Container, Content, ContentOrderInfo, LocationIcon, MapContainer, MapContent, MapHeaderInfo }
