import { OrderAPI } from 'api'
import { getOrderTypeLabel } from 'libs/helpers/get-order-type-label'
import { useEchoConnection } from 'libs/hooks/use-echo-connection'
import { OrderChangeObservable } from 'libs/observables'
import { createContext, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IBag, IBagInfo, IBagType, IModerationOrder } from 'types/bag'
import { IErrorResponse } from 'types/error-response'
import { IOrder } from 'types/order'

export interface IOrderContext {
    saleDetails?: {
        type?: IBagType
        type_label: string
        code?: string
        name: string
    }
    loading: boolean
    messageError?: string
    orders: IOrder[]
    info?: IBagInfo
    moderationOrders: IModerationOrder[]
    isPresential: boolean
}
const InitialValue = {
    loading: false,
    orders: [],
    moderationOrders: [],
    isPresential: false,
}

const OrderContext = createContext<IOrderContext>(InitialValue)

type OrderProps = {
    children: any
}
const OrderProvider: React.FC<OrderProps> = memo(({ children }) => {
    const { saleDetails, orders, info, moderationOrders, loading, messageError, isPresential } =
        useOrderFetch()

    return (
        <OrderContext.Provider
            value={{
                isPresential,
                saleDetails,
                orders,
                info,
                moderationOrders,
                loading,
                messageError,
            }}
        >
            {children}
        </OrderContext.Provider>
    )
})

function useOrderFetch() {
    const { code, bagCode } = useParams()

    const intervalRef = useRef<NodeJS.Timeout>()
    const lastRefreshRef = useRef<Date>()
    const codeRef = useRef<string>()

    const [bag, setBag] = useState<IBag>()
    const [info, setInfo] = useState<IBagInfo>()
    const [orders, setOrders] = useState<IOrder[]>([])
    const [moderationOrders, setModerationOrders] = useState<IModerationOrder[]>([])

    const [loading, setLoading] = useState(false)
    const [messageError, setMessageError] = useState<string>()

    const enable = useMemo(() => {
        return !!bagCode && !orders.find(x => !!x?.dm_order?.times.at_client)
    }, [orders, bagCode])

    const saleDetails = useMemo(() => {
        return {
            type: bag?.type,
            type_label: getOrderTypeLabel(bag?.type),
            name: bag?.sale_channel || 'Delivery Mall',
        }
    }, [bag])

    const isPresential = useMemo(() => {
        if (saleDetails?.type) {
            return !['delivery', 'cargo'].includes(saleDetails.type)
        }
        return false
    }, [saleDetails])

    const socketEvents = useMemo(() => {
        return [
            {
                name: '.new-dmo-order',
                callback: (order: IOrder) => {
                    lastRefreshRef.current = new Date()

                    setBag(state => {
                        const orders = state?.orders || []
                        if (state) {
                            const exits = orders.find(e => e.dm_order?.hash === order.dm_order?.hash)

                            if (exits) {
                                return {
                                    ...state,
                                    orders: state.orders.map(e =>
                                        e.dm_order?.hash === order.dm_order?.hash ? order : e,
                                    ),
                                }
                            }

                            return { ...state, orders: [...state.orders, order] }
                        }
                        return state
                    })
                },
            },
        ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const _getOrder = useCallback(async (code: string) => {
        lastRefreshRef.current = new Date()
        setLoading(true)
        try {
            const result = await OrderAPI.get(code)
            setBag(result)
            setMessageError(undefined)
        } catch (error) {
            const { message } = error as IErrorResponse
            setMessageError(message)
        }
        setLoading(false)
    }, [])

    const _getBag = useCallback(async (uuid: string) => {
        lastRefreshRef.current = new Date()
        setLoading(true)
        try {
            const result = await OrderAPI.getBag(uuid)
            setBag(result)
            setMessageError(undefined)
        } catch (error) {
            const { message } = error as IErrorResponse
            setMessageError(message)
        }
        setLoading(false)
    }, [])

    useEffect(() => {
        if (bag) {
            const { orders, ...rest } = bag
            // const storesSlugs = orders.map(e => e.store.slug)
            setOrders(orders)

            setInfo({
                ...rest,
                delivery_fee: rest.delivery_fee === 'R$ 0,00' ? '' : rest.delivery_fee,
                service_tax: rest.service_tax === 'R$ 0,00' ? '' : rest.service_tax,
                subtotal: rest.subtotal === 'R$ 0,00' ? '' : rest.subtotal,
                total: rest.total === 'R$ 0,00' ? '' : rest.total,
            })
        }
    }, [bag])

    useEffect(() => {
        codeRef.current = bagCode || code
        if (codeRef.current) {
            const callback = () => {
                if (codeRef.current) {
                    if (bagCode) {
                        _getBag(codeRef.current)
                    } else {
                        _getOrder(codeRef.current)
                    }
                }
            }
            callback()
            intervalRef.current = setInterval(() => {
                const d1 = lastRefreshRef.current?.getTime()
                const d2 = new Date().getTime()

                if (d1 && d2) {
                    const diff = Math.round((d2 - d1) / 60000)
                    if (diff >= 2) {
                        callback()
                    }
                }
            }, 120000)
        } else {
            setMessageError('Código de rastreio não encontrado')
        }

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, bagCode])

    useEffect(() => {
        const sub = OrderChangeObservable.subscribe(order => {
            setOrders(state => state.map(e => (e.dm_order?.hash === order.dm_order?.hash ? order : e)))
        })

        return () => {
            if (sub) {
                sub.unsubscribe()
            }
        }
    }, [])

    useEchoConnection({
        code: bagCode,
        enable,
        channelName: bagCode ? `bag.tracker.${bagCode}` : null,
        events: socketEvents,
    })

    return { saleDetails, orders, info, moderationOrders, loading, messageError, isPresential }
}

export { OrderContext, OrderProvider }
