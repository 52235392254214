import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import stories from 'store/reducers'

const persistConfig = {
    key: 'mercadoo',
    whitelist: ['auth', 'addresses', 'settings'],
    storage,
}

const middlewares = [thunk]

middlewares.push()
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const persistedReducer = persistReducer(persistConfig, combineReducers(stories))

export const store = createStore<any, any, any, any>(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middlewares)),
)

export const persistor = persistStore(store)
