import styled from 'styled-components'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.backgroundAccent};

    @keyframes show {
        from {
            opacity: 0;
            margin-top: -20px;
        }
        to {
            opacity: 1;
            margin-top: 0px;
        }
    }
    @keyframes close {
        from {
            opacity: 1;
            margin-top: 0px;
        }
        to {
            opacity: 0;
            margin-top: -20px;
        }
    }

    * {
        box-sizing: border-box;
        ::-webkit-scrollbar {
            width: 8px;
        }
        ::-webkit-scrollbar-track {
            background: ${({ theme }) => theme.colors.backgroundContrast};
        }
        ::-webkit-scrollbar-thumb {
            background: ${({ theme }) => theme.colors.primary};
        }
    }
`

export { Container }
